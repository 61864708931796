import * as React from "react"
import TiltImg from "../../images/tilt.png"

const CustomHeader = ({ title }: { title: string }) => (
  <section className="pt-4 pb-4 mb-1 bg-emerald-50 relative">
    <div className="container px-4  max-w-6xl mx-auto items-center flex flex-wrap">
      <div className="py-3 lg:py-10">
        <h3 className="text-xl md:text-3xl font-bold mb-0">{title}</h3>
      </div>
    </div>
  </section>
)

export default CustomHeader
