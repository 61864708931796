import { Link } from "gatsby"
import * as React from "react"
import CustomHeader from "../components/CustomHeader"
import Layout from "../components/layout"
import Seo from "../components/seo"

const PrivacyPolicy = () => (
  <Layout>
    <Seo title="Privacy policy Sein Tech Partners" />
    <CustomHeader title="Privacy-policy" />
    <div id="privacy-policy" className="body-font mt-2 md:mt-5 pt-10">
      <div className="container px-4  max-w-6xl mx-auto items-left flex flex-col flex-wrap">
        <h1 className="text-center font-bold title-font text-2xl mb-4 sm:mb-4">
          SEIN Tech Partners Privacy Policy
        </h1>
        <section>
          <h2>A. Overview</h2>
          <p>
            As a Subscriber, SEIN Tech Partners collects and processes
            information from you through the Customer’s instance of the Service
            (e.g. a Customer's organization or workspace, but for purposes of
            this Policy referred to as the “Workspace”), including all
            associated messages, attachments, files, tasks, projects and project
            names, team names, channels, conversations, and other content
            submitted through the Service (“Workspace Content”). Because the
            Customer controls the Workspace used by Subscribers, if you have any
            questions about the Customer’s specific Workspace settings and
            privacy practices, please contact the Customer whose Workspace you
            use. If you are a Subscriber located in the European Union, please
            note that the Customer is the data controller with respect to the
            processing of your Workspace Content pursuant to the EU General Data
            Protection Regulation (“GDPR”).
          </p>
        </section>
        <section>
          <h2 className="mt-10">
            B. Collection and Use of Subscriber Information{" "}
          </h2>
          <p>
            This section explains the information we collect from Subscribers.
            We do not require Subscribers to provide us with information.
            However, certain information, such as account log-in data, is
            required to provide you with access to the Service, and other
            information may be collected automatically as you use the Service.
          </p>
          <ul className="list-decimal list-inside space-y-5">
            <li>
              <strong>Workspace Content:</strong> Workspace Content is
              collected, used, and shared by SEIN Tech Partners in
              accordance with the Customer’s instructions, including any
              applicable terms in the Customer Agreement, or as required by
              applicable law. The Customer, and not SEIN Tech Partners,
              determines its own, internal policies regarding storage, access,
              modification, deletion, sharing, and retention of Workspace
              Content which may apply to your use of the Service. For example, a
              Customer may provide or remove access to the Service, enable or
              disable third party integrations, manage permissions, retention
              and export settings, transfer or assign teams, or share projects.
              Please check with the Customer about the policies and settings
              that they have instituted with respect the Workspace Content that
              you provide when using the Service.
            </li>
            <li>
              <strong>Account Information:</strong> To set up your SEIN
              Technology Partners account, you or the Customer will provide us
              with basic information about you which may include your name,
              address, telephone number, email address, and password. You will
              then have the ability to provide optional profile information,
              such as a photograph or basic demographic data. With your
              permission, we may also upload calendar information stored on your
              mobile device to your account. If you submit payment information
              in connection with your use of the Service, we utilize a third
              party credit card payment processing company to collect payment
              information, including your credit card number, billing address,
              and phone number. In such circumstances, the third party service
              provider, and not SEIN Tech Partners, stores your payment
              information on our behalf.
            </li>
            <li>
              <strong>Service Usage Information:</strong> As you use the
              Service, we collect information about how you use and interact
              with the Service (“Service Usage Information”). Such information
              includes:
              <ul className="list-disc list-inside space-y-5">
                <li className="mt-5">
                  <strong>Device information:</strong> When you access the
                  Service using a mobile device, we collect certain device
                  information, including the type of device you are using, its
                  operating system, and mobile network information, which may
                  include your mobile phone number. We may also collect your MAC
                  address and other unique device identifiers.{" "}
                </li>
                <li>
                  <strong>Log files:</strong> When you use the Service, our
                  servers automatically record information in server log files.
                  These log files may include information such as your web
                  request, IP address, browser type and settings, referring/exit
                  pages and URLs, number of clicks, date and time stamp
                  information, language preferences, data from cookies and
                  similar technologies, and other such information.
                </li>
                <li>
                  <strong>Location information:</strong> We collect and process
                  general information about the location of the device from
                  which you are accessing the Service (e.g., approximate
                  geographic location inferred from an IP address).
                </li>
                <li>
                  <strong>Workspace Use Metadata:</strong> When you interact
                  with the Service, metadata is generated that provides
                  high-level (non-content) information about the way you work in
                  your Workspace. For example, we may log the number of
                  Workspaces you work in; the number of tasks to which you are
                  assigned; the features and embedded Service content you
                  interact with; the types of files you share; and what, if any,
                  third party services and integrations you use.
                </li>
              </ul>
            </li>
            <li>
              <strong>Other Information:</strong> You may provide us with
              information when you interact with us in other ways, such as when
              you submit requests or questions to us via forms or email (e.g.,
              support forms, sales forms, user research participation forms);
              information you provide in connection with SEIN Tech
              Partners  sweepstakes, contests, or research studies in which you
              choose to participate; beta testing; and requests for customer
              support and technical assistance (collectively, “Other
              Information”).
            </li>
            <li>
              <strong>
                Information Collected from Third-Party Integrations:
              </strong>{" "}
              If you choose to use or connect to third-party integrations
              through the Service, or if you are required or permitted to do so
              by a Customer, such third parties may allow us to have access to
              and store additional information about your interaction with those
              services as it relates to your use of the Service. If you initiate
              these connections, you also understand that we will share
              information about you that is required to enable your use of the
              third-party integration through the Service. If you do not wish to
              have this information shared, do not initiate these connections.
              By enabling these connections, you authorize us to connect and
              access the information provided through these connections, and you
              understand that the privacy policies of these third parties govern
              such connections.
            </li>
          </ul>
        </section>
        <section>
          <h2 className="mt-10">
            C. How Does SEIN Tech Partners Use Subscriber Information?{" "}
          </h2>
          <p>
            This section explains how SEIN Tech Partners uses information
            collected from Subscribers.
          </p>
          <ul className="list-decimal list-inside space-y-5">
            <li>
              <strong>Workspace Content:</strong> SEIN Tech Partners may
              view and use Workspace Content collected from and about
              Subscribers only as necessary:
              <ul className="list-disc list-inside">
                <li>To maintain, provide and improve the Service</li>
                <li>
                  To prevent or address technical or security issues and resolve
                  support requests{" "}
                </li>
                <li>
                  To investigate when we have a good faith belief, or have
                  received a complaint alleging, that such Workspace Content is
                  in violation of the User{" "}
                  <Link to="/terms-of-use">
                    <span className="link">Terms of Use</span>{" "}
                  </Link>
                </li>
                <li>
                  To comply with a valid legal subpoena, request, or other
                  lawful process{" "}
                </li>
                <li>
                  As otherwise expressly permitted in writing by the Customer{" "}
                </li>
              </ul>
            </li>
            <li>
              <strong>Information:</strong> Account Information, Service Usage
              Information, Information from Third Party Integrations, and Other
              Information. SEIN Tech Partners may use these categories of
              information collected from and about Subscribers to:{" "}
              <ul className="list-disc list-inside">
                <li>Maintain, provide, and improve the Service</li>
                <li>Respond to your requests for information</li>
                <li>
                  Prevent or address technical or security issues and resolve
                  support requests{" "}
                </li>
                <li>
                  Investigate in good faith alleged violations of our User Terms
                  of Service{" "}
                </li>
                <li>
                  Comply with a valid legal subpoena, request, or other lawful
                  process
                </li>
                <li>
                  Help us better understand user interests and needs, and
                  customize the Service for our users{" "}
                </li>
                <li>
                  Engage in analysis, research, and reports regarding use of the
                  Service{" "}
                </li>
                <li>Protect the Service and our users </li>
                <li>
                  Communicate with you via email and through the Service about
                  important notices and updates regarding the Service, such as
                  to inform you about changes in the Service, our service
                  offerings, and important services-related notices, such as
                  about security and fraud. Because these communications are an
                  important part of the Service, you may not opt out of them{" "}
                </li>
                <li>
                  In accordance with applicable legal obligations, communicate
                  with you about promotions, offers, and news about SEIN
                  Tech Partners. You have the ability to unsubscribe from
                  such promotional communications{" "}
                </li>
                <li>
                  Provide cross-device management of your account. For example,
                  we may locate or try to locate the same unique users across
                  multiple browsers or devices (such as smartphones or tablets),
                  or work with service providers that do this, in order to save
                  your preferences across devices and analyze usage of the
                  Service. If you wish to opt out of the ability of one our
                  service providers, Google Analytics, to locate you across
                  devices in this way, you may install the Google Analytics
                  Opt-out Browser Add-on by 
                  <a
                    href="https://tools.google.com/dlpage/gaoptout"
                    target="_blank"
                  >
                    <span className="text-yellow-500 underline cursor-pointer">
                      clicking here
                    </span>{" "}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </section>
        <section>
          <h2 className="mt-10">D. Sharing of Subscriber Information</h2>
          <p>
            In accordance with the applicable Customer Agreement, we may share
            the information we collect from Subscribers as follows:
          </p>
          <ul className="list-decimal list-inside space-y-5">
            <li>
              <strong>Affiliates and Subsidiaries:</strong> We may share the
              information we collect within the SEIN Tech Partners family
              of companies.{" "}
            </li>
            <li>
              <strong>Service Providers:</strong> We may provide access to or
              share your information with select third parties that use the
              information only to perform services on our behalf. These third
              parties provide a variety of services to us, including without
              limitation sales, marketing, provision of content and features,
              analytics, data storage, security, fraud prevention, and other
              services.
            </li>
            <li>
              <strong>Business Transactions:</strong> If the ownership of all or
              substantially all of our business changes, we may transfer your
              information to the new owner so that the Service can continue to
              operate. In such case, your information would remain subject to
              the promises and commitments contained in this Policy until such
              time as the acquiring party modifies it. If such transfer is
              subject to additional mandatory restrictions under applicable
              laws, SEIN Tech Partners will comply with such restrictions.
            </li>
            <li>
              <strong>Consistent with your settings within the Service:</strong>{" "}
              Please note that the Workspace Content you submit through the
              Service may be viewable by other users in your Workspace and
              within your organization, depending on the specific settings you
              and your organization have selected
            </li>
          </ul>
        </section>
        <section>
          <h2 className="mt-10">E. Aggregate De-Identified Data</h2>
          <p>
            We may aggregate and/or de-identify information collected through
            the Service so that such information can no longer be linked to you
            or your device (“Aggregate/De-Identified Information”). We may use
            Aggregate/De-Identified Information for any purpose, including
            without limitation for research and analytics, and may also share
            such data with any third parties, including partners, affiliates,
            services providers, and others.
          </p>
        </section>
        <section>
          <h2 className="mt-10">F. Combined Information</h2>
          <p>
            We may combine the information that we collect through the Service
            with information that we receive from other sources, both online and
            offline, and use such combined information in accordance with this
            Policy and the Customer Agreement.
          </p>
        </section>
        <section>
          <h2 className="mt-10">G. Data Retention</h2>
          <p>
            We will retain your information for the period necessary to fulfill
            the purposes outlined in this Policy unless a longer retention
            period is required or permitted by law, or where the Customer
            Agreement requires or permits specific retention or deletion
            periods.
          </p>
        </section>
        <section>
          <h2 className="mt-10">H. Data Subject Rights</h2>
          <p>
            Please contact your Workspace owner(s) or administrator(s) to
            exercise any data subject rights you have under applicable local
            laws, including your ability to access, delete, rectify, transfer,
            or object under the GDPR.
          </p>
        </section>
      </div>
    </div>
  </Layout>
)

export default PrivacyPolicy
